const [PROD, DEV, LOCAL] = ['prod', 'dev', 'local']

// 正式环境
// const ENV = PROD
// 测试环境
const ENV = DEV
// 本地环境
// const ENV = LOCAL

//服务器地址
const HTTP_URL = 'https://rxzp-sys.gdhrma.com'                /*  正式服  */
const HTTP_URL_DEV = 'https://rxzp-sys-dev.dindong.cn'        /*  测试服  */

const host = window.location.host
const IS_DOMAIN = !host.includes('192.168') && !host.includes('localhost')


// 百度地图key
const BAI_DU_MAP_AK = 'ozT7g2K7TQBlwdTOCKukAqTWOrXG9TCd'
// 天气api
const GET_WEATHER_URL = 'https://tianqiapi.com/api/'
// oss云存储地址
const OSS_URL = ''

const config = {
    [PROD]: {
        API_BASE_URL: HTTP_URL + '/api/',
        TOP_LEVEL_DOMAIN: '.gdhrma.com',
    },

    [DEV]: {
        API_BASE_URL: HTTP_URL_DEV + '/api/',
        TOP_LEVEL_DOMAIN: '.dindong.cn',
    },

    [LOCAL]: {
        API_BASE_URL: 'http://192.168.1.3' + '/api/',
        TOP_LEVEL_DOMAIN: '',
    }
}

const {
    API_BASE_URL,
    TOP_LEVEL_DOMAIN,
} = config[ENV]

export {
    HTTP_URL,
    HTTP_URL_DEV,
    API_BASE_URL,
    TOP_LEVEL_DOMAIN,
    IS_DOMAIN,
    BAI_DU_MAP_AK,
    GET_WEATHER_URL,
    OSS_URL,
}

